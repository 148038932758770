import cs from '../../assets/cs.webp'
import znv from '../../assets/znv.webp'
import w from '../../assets/w.webp'
import am from '../../assets/am.webp'
import ppl from '../../assets/ppl.webp'
import mjd from '../../assets/mjd.webp'
import bl from '../../assets/bl.webp'
import mnh from '../../assets/mnh.webp'

export const logos = [
  cs,
  znv,
  w,
  am,
  ppl,
  mjd,
  bl,
  mnh,
  ];